.middle-top {
  position: relative;
  height: 33.34vw;
  background-position: center;
  background-size: cover;
  @include mq($until: md) {
    height: 320px;
  }
}

.content-page {
  h4 {
    font-size: rem(30px);
    color: $color-main;
    margin-bottom: 30px;
  }
  &:after {
    content: "";
    height: 50vw;
    background-image: url(./../../images/absolute-element.png);
    width: 39vw;
    display: block;
    top: 27.5vw;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @include mq($until: md) {
    &:after {
      width: 56vw;
      top: 305px;
    }
  }
}

.page-caption {
  padding: 0 6vw;
  margin-top: 60px;
  @include mq($until: md) {
    padding: 0 6vw;
    margin-top: 40px;
  }
}

.section-one, .section-two {
  svg {
    width: 43vw;
  }
  display: flex;
  align-items: center;
  margin-top: 60px;
  .text-content {
    max-width: 46vw;
    margin: 0 6vw;
  }
  @include mq($until: md) {
    .text-content {
      padding: 0 6vw;
      max-width: initial;
      margin-top: 40px;
    }
    flex-direction: column;
    margin-top: 30px;
  }
}

@include mq($until: md) {
  .left-side-hex, .right-side-hex {
    position: relative;
    svg {
      width: 30vw;
    }
  }
  .left-side-hex {
    right: 35vw;
  }
  .right-side-hex {
    left: 35vw;
  }
  .section-two {
    flex-direction: column-reverse;
  }
}

/* GALERIA */

.hex-gallery {
  position: relative;
  padding: 0 6vw;
  display: flex;
  align-items: center;
  a {
    width: 100%;
    text-align: center;
  }
  h2 {
    font-weight: bold;
    font-size: rem(41px);
    color: #f26f20;
    text-shadow: none !important;
    margin-top: 0;
  }
  @include mq($until: md) {
    margin-top: 40px;
    flex-direction: column-reverse;
    svg {
      width: 86vw;
      margin-top: 20px;
    }
  }
  &:after {
    content: "";
    height: 41vw;
    background-image: url(./../../images/galery-bg-2.png);
    width: 35.7vw;
    display: block;
    top: 0;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    @include mq($until: md) {
      height: 60vw;
      width: 49.7vw;
      top: 17vw;
    }
  }
  &:before {
    content: "";
    height: 41vw;
    background-image: url(./../../images/galery-bg-1.png);
    width: 35.7vw;
    display: block;
    top: 0;
    left: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: -1;
    @include mq($until: md) {
      height: 60vw;
      width: 49.7vw;
      top: 17vw;
    }
  }
}





