.header__submenu {
  position: absolute;
  display: flex;
  flex-direction: column;
  background: #1B181F;
  align-items: flex-start;
  justify-content: flex-start;
  max-width: unset;
  top: 1vw;
  padding: 10px;
  height: auto;
  max-height: 900px;
  min-width: 150px;
  opacity: 1;
  z-index: 9;
  transform: scaleY(1);
  transform-origin: top center;
  transition: opacity .6s ease-in-out, transform .3s ease-in-out;

  li {
    padding: 0;
  }

  &--fullscreen {
    position: static;
    background-color: transparent;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    &.header__submenu--hidden{
      display: none;
    }
  }

  &--hidden {
    opacity: 0;
    transform: scaleY(0);
  }

  .header__menuItem {
    width: auto;
    margin: 5px;

    &--fullscreen {
      margin: 0;
    }
  }

  .header__menuLink {
    color: #fff;
    white-space: nowrap;

    &--fullscreen {
      font-size: rem(16px);
      margin-bottom: 5px;
      text-align: center;
    }
  }
}

.header__menuItem--hasSubmenu {
  position: relative;
  &::after {
    content: '';
    display: inline-block;
    border: none;
    background: url(./../../images/tri-one.svg) no-repeat;
    height: 12px;
    width: 15px;
    background-size: contain;
    vertical-align: 0;
    margin-left: 5px;
    @include mq($until: xl) {

    }
    @include mq($until: lg) {
      min-width: 14px;
    }
  }
}


.header__menuItem:nth-child(1) .header__submenu {
  right: unset;
  left: 0;
}