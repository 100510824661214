/* FOOTER */

.footer {
  
  li {
    &:before {
      display: none;
    }
  }

  z-index: 2;
  position: relative;
  .container {
    max-width: 88vw;
    @include mq($until: md) {
      padding: 0;
    }
  }
  h6 {
    display: inline;
  }
  img {
    height: auto;
    width: 14vw;
    @include mq($until: lg) {
      width: 20vw;
    }
    @include mq($until: sm) {
      width: 30vw;
    }
  }
}

.footer-nav {
  display: flex;
  justify-content: space-between;
  place-items: flex-end;
  margin-top: 50px;
  margin-bottom: 50px;
  @include mq($until: lg) {
    display: flex;
    place-items: flex-end;
    flex-wrap: wrap;
    justify-content: space-around;
    > * {
      margin-bottom: 40px;
    }
  }
  @include mq($until: md) {
    flex-flow: wrap;
    display: flex;
    justify-content: space-around;
    place-items: normal;
    margin-bottom: 0;
  }
  a {
    text-transform: uppercase;
  }
  a, h6 {
    font-weight: bold;
    font-size: rem(24px);
    @include mq($until: sm) {
      font-size: rem(19px);
    }
  }
  span {
    font-size: rem(25px);
    font-weight: bold;
    color: $color-main;
  }
  div {
    &:last-of-type {
      a {
        color: gray;
      }
    }
  }
}

footer.footer::after {
  content: "";
  width: 64vw;
  position: absolute;
  background: url(./../../images/FOOTER-ABSOLUTE-ELEMENT.png) no-repeat;
  height: 27vw;
  background-size: cover;
  z-index: 177;
  bottom: 59px;
  pointer-events: none;
}

.footer-nav div:last-of-type a:first-of-type::after {
  content: '|';
  display: inline-block;
  color: $color-main;
  margin: 0 6px 0 11px;
  font-size: larger;
}

.contacts {
  background-repeat: no-repeat;
  background-size: cover;
  .container {
    display: flex;
    align-items: center;
  }
  h3 {
    font-weight: 500;
    font-size: rem(45px);
    color: white;
    margin-right: 9vw;
    text-align: center;
    width: 100%;
    span {
      color: inherit;
      font-size: inherit;
      margin-left: 130px;
    }
  }
  ul {
    color: white;
    list-style: none;
    margin-top: 60px;
    li {
      color: white;
      font-weight: 500;
      font-size: rem(24px);
      margin-bottom: 40px;
      padding: 0;
      margin-left: 30px;
      margin-top: 0;
    }
  }
}

.footer-installations {
  a {
    margin-right: 30px;
    @include  mq($until: xs) {
      font-size: 1.2rem;
    }
  }
  h6 {
    margin-right: 20px;
    margin-bottom: 0;
    @include  mq($until: xs) {
      display: none;
    }
  }
  @include  mq($until: md) {
    display: flex;
    width: 100%;
    max-width: 550px;
    justify-content: space-between;
    align-items: center;
    a {
      margin: 0;
    }
  }
}

.sub-footer {
  height: 60px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  a {
    color: gray;
  }
}

footer.footer svg {
  position: absolute;
  fill: $color-main;
  width: 20px;
  @include  mq($until: md) {
    width: 18px;
    height: 22px;
  }
}


@media (max-width: 1470px) {
  .contacts h3 {
    margin-right: 4vw;
  }
}

@media (max-width: 1200px) {
  .contacts h3 {
    margin-right: 0;
    font-size: 3vw;
  }
}

@media (max-width: 988px) {
  .contacts h3 {
    margin-right: 0;
    font-size: 3vw;
  }
  .contacts ul {
    color: white;
    list-style: none;
    margin-top: 30px;
    padding: 0;
  }
  .contacts ul li {
    font-size: 18px;
    margin-bottom: 15px;
  }
}

@media (max-width: 655px) {
  .contacts h3 {
    font-size: 4vw;
    margin-left: 10px;
    span {
      margin-left: 0;
    }
  }
  .contacts h3 br {
    display: none;
  }
}

@media (max-width: 425px) {
  .footer-nav a, .footer-nav h6 {
    font-size: 14px;
  }
  .contacts ul li {
    font-size: 14px;
  }
}

