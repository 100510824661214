/* SECTION TOP */

.dropdown-toggle::after {
  border: none;
  background: url(./../../images/tri-one.svg) no-repeat;
  height: 12px;
  width: 15px;
  background-size: contain;
  vertical-align: 0;
}
section.intro {
  height: 41.5vw;
  width: 100vw;
  background: #676767 no-repeat center;
  background-size: cover;
  h1 {
    font-weight: 600;
    font-size: 3.13vw;
    color: white;
    margin-left: 24vw;
    margin-bottom: 5vw;
    @include mq($until: md) {
      font-size: 5.5vw;
      margin-bottom: 10vw;
      margin-left: 18vw;
    }
    span {
      margin-left: -167px;
      font-size: inherit;
      color: inherit;
      @include mq($until: md) {
        margin-left: -20px;
      }
    }
  }
  .intro-header {
    display: flex;
    height: 80%;
    align-items: flex-end;
    position: relative;
    top: 20%;
  }
  @include mq($until: md) {
    height: 72.8vw;
  }
}

.button-down {
  width: 24vw;
  z-index: 10;
  position: absolute;
  background: linear-gradient(-128deg,
          #f5f5f500 15%,
          rgb(255, 255, 255) 0);
  height: 5vw;
  bottom: -1px;
  left: 0;
  @include mq($until: md) {
    height: 9vw;
    width: 39vw;
  }
  a {
    display: flex;
    padding-left: 6vw;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: rem(28px);
    font-weight: 600;
    color: black;
    @include mq($until: xxl) {
      font-size: rem(24px);
    }
    @include mq($until: xl) {
      font-size: rem(20px);
    }
    @include mq($until: lg) {
      font-size: rem(16px);
    }
    @include mq($until: md) {
      font-size: 3.5vw;
    }
    i {
      background: url(./../../images/tri-one.svg) no-repeat;
      display: block;
      height: 15px;
      width: 18px;
      margin-left: 10px;
      background-size: cover;
      @include mq($until: xl) {
        height: 10px;
        width: 10px;
        margin-left: 5px;
      }
      @include mq($until: md) {
        height: 1.8vw;
        width: 2.1vw;
      }
    }
  }
}

g#button2 {
  .st8 {
    cursor: pointer;
    @include hover-transition;
    &:hover {
      fill: #1B181F;
    }
  }
  tspan {
    font-weight: 600;
    font-size: rem(26px);
    fill: white;
    pointer-events: none;
    @include mq($until: sm) {
      font-size: rem(38px);
    }
  }
  @include mq($until: md) {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    -webkit-transform-origin: 81% 12%;
    transform-origin: 81% 12%;
  }
}




////////* SVG SECTION TOP *////////

.svg-intro-group {
  position: absolute;
  width: 100%;
  top: 0;
  @include mq($until: md) {
    top: 9.2vw;
  }
}

g#graphic{
  @include mq($until: md) {
    -webkit-transform: scale(1.75);
    transform: scale(1.75);
    -webkit-transform-origin: 100% 12%;
    transform-origin: 100% 12%;
  }
}

svg {
  overflow: initial;
}

g#graphic {
  opacity: .2;
}

section.intro {
  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
  .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#949494;}
  .st2{fill-rule:evenodd;clip-rule:evenodd;fill:#E5E5E5;}
  .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#BBBBBB;}
  .st4{fill:#949494;}
  .st5{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
  .st6{fill:#E5E5E5;}
  .st7{fill:#BBBBBB;}
}

.svg-intro-group {
  .st8{fill-rule:evenodd;clip-rule:evenodd;fill:#F26F20;}
}

/* SECTION INSTALLATIONS */

.caption {
  p:last-of-type {
    display: inline;
  }
  display: none;
  margin: 40px 6vw;
  p {
    a {
      text-decoration: underline;
    }
  }
  h2 {
    font-weight: 600;
    font-size: rem(41px);
    margin-bottom: 60px;
    strong {
      color: $color-main;
      font-weight: bold;
      font-size: inherit;
    }
    @include mq($until: lg) {
      margin-bottom: 30px;
      font-size: 3.13vw;
    }
    @include mq($until: md) {
      font-size: 5.5vw;
    }
  }
}

.free-estimate {
  a {
    display: block;
    margin-top: 40px;
    font-weight: bold;
    font-size: 21px;
    margin-left: 6vw;
    @include mq($until: md) {
      font-size: rem(15px);
    }
  }
}

.active {
  display: block!important;
}

.installations {
  align-items: center;
  display: flex;
  position: relative;
  width: 100vw;
  height: 47vw;
  .install-bg {
    display: block;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    opacity: .2;
    z-index: -1;
    overflow: hidden;
    @include mq($until: md) {
      overflow: initial;
    }
    svg#bg {
      @include mq($until: md) {
        -webkit-transform: scale(1.8);
        transform: scale(1.8);
        -webkit-transform-origin: 100% 1%;
        transform-origin: 100% 1%;
      }
    }
  }
  @include mq($until: md) {
    height: auto;
    align-items: center;
    flex-flow: column-reverse;
  }
}

.hexagons {
  margin-top: 38px;
}

.install-bg::after {
  content: "";
  height: 52.5vw;
  background-image: url(./../../images/sek2-bg.svg);
  width: 102vw;
  display: block;
  top: 0;
  right: 0;
  position: absolute;
  background-repeat: no-repeat;
  background-size: contain;
  @include mq($until: md) {
    height: 100%;
    width: 178vw;
  }
}


/* SECTION INSTALLATION SVG */

section.installations {
  .st0{fill-rule:evenodd;clip-rule:evenodd;fill:none;}
  .st1{fill-rule:evenodd;clip-rule:evenodd;fill:#949494;}
  .st2{fill:#E5E5E5;}
  .st3{fill-rule:evenodd;clip-rule:evenodd;fill:#E5E5E5;}
  .st4{fill-rule:evenodd;clip-rule:evenodd;fill:#BBBBBB;}
  .st5{fill:#BBB4AD;}
  .st6{fill:none;}
  .st7{fill:#FFFFFF;}
}

/* GROUP HEXAGONS */

.hexagons {
  g {
    cursor: pointer;
    transform-origin: 50% 50%;
    @include hover-transition;
    &:hover {
      opacity: 1;
      transform: scale(1.01);
    }
  }
  text {
    pointer-events: none;
  }
  tspan {
    font-size: rem(41px);
  }
  svg {
    @include mq($until: lg) {
      width: 49vw;
    }
    @include mq($until: md) {
      width: 75vw;
    }
  }
}

/* SECTION NEWS */

.actual {
  position: relative;
  h2 {
    margin-left: 6vw;
    margin-bottom: 80px;
    font-weight: bold;
    font-size: 41px;
    color: #A1A1A1;
    @include mq($until: lg) {
      margin-bottom: 30px;
      font-size: 3.13vw;
    }
    @include mq($until: md) {
      font-size: 5.5vw;
      position: absolute;
      top: 12vw;
    }
  }
}

.inner-actual {
  overflow: hidden;
  display: flex;
  width: 100vw;
  align-items: center;
  justify-content: space-between;
  @include mq($until: md) {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    -ms-flex-flow: column-reverse;
    flex-flow: column-reverse;
  }
  h3 {
    font-weight: bold;
  }
  .read-more {
    margin-top: 16px;
    font-weight: 600;
    font-size: 21px;
    @include mq($until: md) {
      font-size: rem(15px);
    }
  }
}

.article {
  flex-flow: column;
  height: auto;
  max-width: 610px;
  justify-content: space-between;
  margin: 40px 60px;
  h3 {
    margin-bottom: 25px;
    @include mq($until: lg) {
      font-size: 1.5rem;
      margin-top: 0;
    }
  }
  @include mq($until: md) {
    max-width: initial;
    margin: 10vw 6vw;
  }
}

img.hidden {
  display: none;
}

/* SVG SECTION NEWS */

svg#left {
  margin-left: -13vw;
  @include mq($until: md) {
    width: 93vw;
    margin-left: -53vw;
  }
}

svg#right {
  margin-right: -6.2vw;
  filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.4));
  @include mq($until: md) {
    margin-right: -75.2vw;
    width: 50vw;
  }
}

.actual-bg {
  position: absolute;
  top: 0;
  z-index: -2;
  @include mq($until: md) {
    display: none;
  }
}


/* SECTION FOUR */

.all-news {
  position: absolute;
  top: 0;
  background: linear-gradient( -51deg, #f5f5f500 15%, #ffffff 0);
  width: 26.2vw;
  height: 5.3vw;
  z-index: 10;
  @include mq($until: md) {
    width: 47vw;
    height: 10vw;
  }
  a {
    display: flex;
    padding-left: 6vw;
    width: 100%;
    height: 100%;
    align-items: center;
    font-size: 1.3vw;
    font-weight: 600;
    @include mq($until: md) {
      font-size: 2.7vw;
    }
  }
}


section.section-four {
  &:after {
    content: "";
    background-image: url("./../../images/absolute-element.png");
  }
  position: relative;
  h2 {
    font-weight: bold;
    color: #FFFFFF;
    font-size: 3vw;
    @include mq($until: md) {
      font-size: 5.5vw;
    }
    span {
      margin-left: 21vw;
      color: inherit;
      font-size: inherit;
    }
  }
  .slogan {
    background: url(./../../images/imagesec4.jpg);
    width: 100vw;
    height: 44.5vw;
    background-size: cover;
    @include mq($until: md) {
      height: 60.5vw;
    }
    div {
      margin-left: 6vw;
      height: 75%;
      display: flex;
      align-items: flex-end;
    }
  }
}

.about {
  margin: 20px 6vw 0;
  min-height: 14.5vw;
  p {
    padding: 0;
  }
}

/* SECTION 4 SVG */

path#b4el2{
  @include mq($until: md) {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    -webkit-transform-origin: 44% 40%;
    transform-origin: 44% 40%;
  }
}

path#b4el3{
  @include mq($until: md) {
    -webkit-transform: scale(1.8);
    transform: scale(1.8);
    -webkit-transform-origin: 97% 40%;
    transform-origin: 97% 40%;
  }
}

g#b4group {
  @include mq($until: md) {
  -webkit-transform: scale(1.8);
  transform: scale(1.8);
  -webkit-transform-origin: 97% 41%;
  transform-origin: 97% 41%;
  }
}

.bg4 {
  position: absolute;
  width: 100%;
  display: block;
  top: 0;
  pointer-events: none;
}
