.small-top {
  position: relative;
  height: 17.71vw;
  background-position: center;
  background-size: cover;
  @include mq($until: md) {
    height: 250px;
  }
  &+article {
    margin: 0 6vw;
    padding: 160px 0 70px 0;
    display: flex;
    &:not(.only-text):after{
      content: "";
      height: 50vw;
      background-image: url(./../../images/subpagebg.png);
      width: 48.7vw;
      display: block;
      top: 33.6vw;
      right: 0;
      opacity: .8;
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 0;
      @include mq($until: md) {
        height: 62vw;
        width: 67.3vw;
        top: 54vw;
      }
    }
    @include mq($until: md) {
      padding: 60px 0;
      flex-direction: column;
    }
  }
}

.subsite:not(.text-page) {
  &:after {
    content: "";
    height: 50vw;
    background-image: url(./../../images/absolute-element.png);
    width: 40vw;
    display: block;
    top: 5.2vw;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @include mq($until: md) {
    &:after {
      width: 56vw;
      top: 14vw;
    }
  }
}

.post-title {
  background: linear-gradient(-129deg, #ffffff00 12%, #ffffff 0);
  height: 5.26vw;
  width: 70%;
  position: absolute;
  bottom: -2px;
  display: flex;
  align-items: flex-end;
  @include mq($until: md) {
    height: 14.2vw;
    background: linear-gradient(-129deg, #ffffff00 17%, #ffffff 0);
    width: 75%;
  }

  h1, h2 {
    margin-left: 6vw;
    font-weight: bold;
    line-height: .7;
    font-size: rem(41px);
    color: $color-main;
    text-shadow: none !important;
    margin-bottom: 0;
    max-width: 75%;
  }
  @include mq($until: sm) {
    width: 100%;
    h1, h2 {
      font-size: rem(33px);
      max-width: 80%;
    }
  }
}

.main-text {
  padding: 0;
}

.sidehex {
  z-index: 1;
  position: relative;
  left: 10vw;
  z-index: 2;
  svg {
    width: 43vw;
    max-width: 800px;
  }
  @include mq($until: md) {
    left: 45vw;
    margin-top: 30px;
    svg {
      width: 50vw;
    }
  }
}


/* TEXT PAGE */


.text-page {
  &:after {
    content: "";
    height: 27vw;
    background-image: url(./../../images/textsubpage.png);
    width: 29vw;
    display: block;
    top: 5.2vw;
    right: 0;
    position: absolute;
    background-repeat: no-repeat;
    background-size: contain;
  }
  @include mq($until: md) {
    &:after {
      width: 44vw;
      top: 15.5vw;
    }
  }
}

.only-text {
  min-height: 500px;
}

/* GALLERY PAGE */

article.custom-gallery {
  padding: 100px 6vw;
  z-index: 1;
  h2 {
    font-weight: bold;
    font-size: rem(41px);
    color: $color-main;
    @include mq($until: lg) {
      font-size: 3.13vw;
    }
    @include mq($until: md) {
      font-size: 5.5vw;
    }
  }
}


