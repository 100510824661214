/* Header - Navbar */

.navbar-dark .navbar-toggler {
  border: none;
}

.nav-items {
  display: flex;
}

a.nav-link.dropdown-toggle {
  padding: 0;
}


.header-logo {
  z-index: 1;
  a {
    height: 5.26vw;
    max-width: 455px;
    img {
      width: auto;
      height: 5.26vw;
      @include mq($until: md){
        height: 14.2vw;
      }
    }
  }
}

.header-inner {
  display: flex;
  margin-right: 12vw;
  align-items: flex-end;
  z-index: 10;
  position: relative;
  @include mq($until: md) {
    align-items: center;
  }
}

.dropdown-menu.show {
  background-color: #1b181f;
}

.dropdown-item:focus, .dropdown-item:hover {
  background-color: #00000000;
}

.nav {
  margin-bottom: 1vw;
  li {
    &:before {
      display: none;
    }
    margin: 0 1.25vw;
    @include mq($until: xl) {
      margin: 0 1.2vw;
    }
    @include mq($until: lg) {
      margin: 0 1vw;
    }
  }
  a {
    color: white;
    font-weight: 600;
    font-size: rem(18px);
    &:hover {
      color: $link-hover-color;
    }
    @include mq($until: xxl){
      font-size: rem(16px);
    }
    @include mq($until: lg){
      font-size: rem(13px);
    }
    @include mq($until: md){
      font-size: rem(20px);
    }
  }
}

header {
  display: flex;
  background: linear-gradient( -129deg, #ffffff 12%, #1B181F 0);
  height: 5.26vw;
  @include mq($until: md){
    height: 14.2vw;
    background: linear-gradient(-129deg, #ffffff 10%, #1B181F 0);
  }
}

.header-button {
  background: linear-gradient( 128deg,
          rgba(2,0,36,0) 21%,
          $color-main 21%,
          $color-main 79%,
          rgba(121,9,9,0) 79%);
  z-index: 5;
  position: absolute;
  top: 5.2vw;
  right: 6.3vw;
  height: 5.26vw;
  width: 24.7vw;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: .2s;
  cursor: pointer;
  &:hover{
    transition: .2s;
    background: linear-gradient( 128deg,
            rgba(2,0,36,0) 21%,
            #1B181F 21%,
            #1B181F 79%,
            rgba(121,9,9,0) 79%);
  }
  span {
    color: white;
    font-weight: 600;
    font-size: 1.14vw;
  }
  @include mq($until: md) {
    background: linear-gradient(128deg,
            rgba(2, 0, 36, 0) 17%,
            $color-main 15%,
            $color-main 83.6%,
             rgba(121, 9, 9, 0) 81%);
    top: 14.2vw;
    height: 9.26vw;
    width: 36vw;
    right: 0.3vw;
    &:hover {
      background: linear-gradient(128deg,
              rgba(2, 0, 36, 0) 17%,
              #1B181F 15%,
              #1B181F 83.6%,
              rgba(121, 9, 9, 0) 81%);
    }
    span {
      font-size: 2vw;
      @include mq($until: xs) {
        font-size: 2.5vw;
      }
    }
  }
}